@font-face {
    font-family: neon;
    src: url("hello-denver.ttf");
}

main.create-url {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: calc(100dvh - 40px);
    overflow: hidden;
    padding-bottom: 40px;
}

main.create-url .neon {
    color: #fff;
    z-index: 8;
    word-break: keep-all;
    white-space: nowrap;
    filter: drop-shadow(0 0 5px #ffffff) drop-shadow(0 0 10px #ffffff) drop-shadow(0 0 20px #bc13fe) drop-shadow(0 0 40px #bc13fe);
}

main.create-url .title {
    font-family: neon, sans-serif;
    font-size: 5rem;
    letter-spacing: 0.8rem;
    transform: translateY(80px);
}

main.create-url .subtitle {
    font-size: 1.8rem;
    transform: translateY(40px);
}

main.create-url .spaceship-wrapper {
    height: 500px;
    width: 500px;
    position: relative;
    animation: rotate 30s linear infinite;
    z-index: 10;
}

main.create-url .spaceship-wrapper .moon {
    animation: rotate 15s linear infinite reverse;
    filter: drop-shadow(0 0 8px #fff);
}

main.create-url .spaceship-wrapper .spaceship {
    position: absolute;
    top: -10px;
    left: -10px;
    transform: rotate(-5deg);
    filter: drop-shadow(0 0 3px #fff);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

main.create-url .star {
    height: 20px;
    width: 20px;
    opacity: 0;
    z-index: 2;
    filter: drop-shadow(0 0 5px #fff);
}

main.create-url .shooting-star {
    filter: drop-shadow(0 0 5px #fff) blur(1px);
}

@keyframes twinkle {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

main.create-url .url-input {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    padding: 1rem 2rem;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 50px;
    outline: 2px solid white;
    transition: 0.125s ease-in-out;
    margin-top: 90px;
    cursor: pointer;
    z-index: 8;
}

main.create-url.no-connection .url-input,
main.create-url.no-connection .url-input * {
    cursor: not-allowed;
    outline-color: grey;
    color: grey;
}

main.create-url .url-input * {
    border: none;
    color: white;
    z-index: 12;
    font-size: 1.3rem;
    cursor: pointer;
}

main.create-url .url-input.active {
    outline-color: white;
    max-width: 520px;
    width: 520px;
    cursor: auto;
}

main.create-url .url-input.active * {
    cursor: auto;
}

main.create-url .url-input input {
    font-family: "Courier New", Courier, monospace;
    outline: none;
    background: transparent;
    width: 0;
    padding-left: 0;
    transition: 0.125s ease-in-out;
}

main.create-url .url-input.active input {
    width: 100%;
    padding-left: 1rem;
}

footer.create-url-footer {
    width: 100%;
    color: white;
    background: black;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-inline: 1rem;
}

footer.create-url-footer > * {
    flex: 1;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Courier New", Courier, monospace;
    text-align: center;
    opacity: 0.4;
}

@media (max-width: 768px) {
    main.create-url .title {
        font-size: 1.7rem;
        letter-spacing: 0.6rem;
        margin-bottom: 45px;
    }

    main.create-url .subtitle {
        font-size: 1rem;
        margin-bottom: -95px;
    }

    main.create-url.complete .subtitle {
        margin-bottom: 20px;
    }

    main.create-url .spaceship-wrapper {
        scale: 0.5;
        margin-bottom: -100px;
    }

    main.create-url.complete .spaceship-wrapper {
        margin-bottom: -60px;
    }

    main.create-url .star {
        height: 10px;
        width: 10px;
    }

    main.create-url .url-input {
        margin-top: 40px;
    }

    main.create-url .url-input.active {
        max-width: 320px;
        width: 320px;
    }

    footer.create-url-footer {
        height: 20px;
        padding-inline: 0.5rem;
    }
}
